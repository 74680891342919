<template>
  <div></div>
</template>

<script>
export default {
  name: "Empty",
  mounted() {
    this.$i18n.locale = "lt";
  },
};
</script>

<style lang="scss" scoped></style>
